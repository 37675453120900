import React from "react";
import { FooterData } from "../App";
import { NavLink } from "react-router-dom";
interface FooterProps {
  footerData: FooterData;
  handleScroll: (sectionRef: any) => void;
  aboutRef: any;
  projectsRef: any;
}

// interface HeaderProps {
//   handleScroll: (sectionRef: any) => void;
//   aboutRef: any;
//   projectsRef: any;
//   headerData: HeaderData;
// }
function Footer({ footerData, handleScroll, aboutRef, projectsRef }: FooterProps) {
  return (
    <footer className="bg-white rounded-lg shadow dark:bg-gray-900 m-4 mt-auto">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a href="https://flowbite.com/" className="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
            <img src={`${footerData.logo_image}`} className="h-8" alt="Flowbite Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">{footerData.logo_text}</span>
          </a>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <button type="button" onClick={() => handleScroll(aboutRef)} className="hover:underline me-4 md:me-6">
                Про нас
              </button>
            </li>
            <li>
              <button type="button" onClick={() => handleScroll(projectsRef)} className="hover:underline me-4 md:me-6">
                Наші проекти
              </button>
            </li>
            <li>
              <NavLink to="/projects-archive" className="hover:underline me-4 md:me-6">
              Звіти по проектам
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact" className="hover:underline">
              Контакти
              </NavLink>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">{footerData.copyrights}</span>
      </div>
    </footer>
  );
}

export default Footer;
